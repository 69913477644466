// Version 2022.09.01

import Splide from "./splide.min.js";

// RELOAD
export function ready(callBackFunc) {
    if (document.readyState !== 'loading') {
        callBackFunc();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', callBackFunc);
    } else {
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callBackFunc();
            }
        });
    }
}

export const whatServer = function () {
    return (window.location.hostname === 'localhost') ? window.location.origin + '/safeguard-au' : window.location.origin;
}

export const whatTheme = function () {
    var urlPath = window.location.hostname;

    if (urlPath === 'localhost') {
        return 'safeguard-au';
    } else if (urlPath.includes('test') === true) {
        return 'safeguard-au';
    } else {
        return 'safeguard-theme';
    }
}

export const connectingData = function (openURL) {
    // Office PC Local
    // const ourPassword = 'PZVV xTfC MywA p5KV ZDkR 5s0B';
    // const ourUsername = 'webguy_fd';

    // Test Site
    // const ourPassword = 'xjnk sgga oBmZ 5Jga La8y 81gK';
    // const ourUsername = 'webguy2021';

    const ourPassword = 'BGW9 dFz5 yScz WDaB mGkU HmBV';
    const ourUsername = 'webguy-sg-app';

    // Laptop Local
    // const ourPassword = 'a214 grOA AkRU ARD3 5dAc KMvb';
    // const ourUsername = 'webguy_safeguard_au';

    const ourRequest = new XMLHttpRequest();

    return new Promise(function (resolve, reject) {
        ourRequest.onreadystatechange = function () {
            if (ourRequest.readyState !== 4) return;

            if (ourRequest.status >= 200 && ourRequest.status < 400) {
                resolve(ourRequest);
            } else {
                reject({
                    status: ourRequest.status,
                    statusTest: ourRequest.statusText
                });
            }
        };

        ourRequest.open('GET', openURL);
        ourRequest.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        ourRequest.setRequestHeader("Authorization", "Basic " + Buffer.from(`${ourUsername}:${ourPassword}`).toString("base64"));
        ourRequest.withCredentials = true;
        ourRequest.send();
    });
}

// FEATURED IMAGES
export const wpMediaPhoto = async (id, size) => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/media' + id);
        let jsonData = JSON.parse(results.responseText);

        return jsonData.media_details.sizes[size].source_url;
    } catch (err) {
        return err;
    }
}

// ALL CATEGORIES
export const getSubCatgories = async (id = '/?per_page=100') => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/categories' + id);
        return JSON.parse(results.responseText);
    } catch (err) {
        return err;
    }
}

// CREATES EVENT LISTENERS
export const addGlobalEventListeners = function (type, selectors, callback) {
    for (let s = 0; s < selectors.length; s++) {
        document.addEventListener(type, e => {
            if (e.target.matches(selectors[s])) callback(e)
        });
    }
}

// PAGES
export const getPages = async (slug = '/?per_page=100') => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/pages' + slug);
        return JSON.parse(results.responseText);
    } catch (err) {
        return err;
    }
}

// ONLINE STORES
export const onlineStores = async (endpoint = '/?per_page=100') => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/safeguard-stores' + endpoint);
        return JSON.parse(results.responseText);
    } catch (err) {
        return err;
    }
}

// SPLIDE MOUNTING
export const mountTheSplide = (splideInit, type) => {
    switch (type) {
        case 'trusted-top-fleets':
            var args = {
                type: 'loop',
                padding: '40px',
                autoplay: true,
                gap: 10,
                fixedWidth: '240px',
                focus: 'center',
                trimSpace: false,
                pagination: false,
                arrows: false,
                speed: 1000,
                interval: 2000,
                drag: false,
                pauseOnFocus: false,
            }
            break;
        case 'customer-reviews':
            var args = {
                type: 'loop',
                padding: '40px',
                autoplay: true,
                gap: 10,
                fixedWidth: '350px',
                focus: 'center',
                trimSpace: false,
                pagination: false,
                arrows: false,
                speed: 1000,
                interval: 2000,
                drag: false,
                pauseOnFocus: false,
            }
            break;
        case 'net-sizes':
            var args = {
                padding: '60px',
                autoplay: true,
                gap: 15,
                rewind: true,
                perPage: 5,
                breakpoints: {
                    1200: { perPage: 4 },
                    992: { perPage: 3 },
                    768: { perPage: 2, perMove: 2 },
                    620: { perPage: 1, focus: 'center', trimSpace: false }
                }
            }
            break;
        case 'gallery':
            var args = {
                type: 'loop',
                gap: 15,
                autowidth: true,
                focus: 'center',
                trimSpace: false,
                start: 0,
                autoplay: true
            }
            break;
        default:
            break;
    }

    const splideContainer = new Splide(splideInit, args);
    splideContainer.mount();
}

// PRDOUCT CAROUSEL
export const productCarousel = () => {
    var main = new Splide('#safeguard-net-image-carousel', {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false
    });

    var thumbnails = new Splide('#safeguard-net-thumbnail-carousel', {
        perPage: 5,
        padding: '60px',
        gap: 10,
        rewind: true,
        pagination: false,
        isNavigation: true,
        trimSpace: true,
        breakpoints: {
            1080: { perPage: 4 },
            992: { perPage: 3 },
            420: { perPage: 2 }
        }
    });

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
}

// SIZING TOOL BUTTONS
export const sizingToolButtonSwitch = (data, section) => {
    const buttons = document.querySelectorAll('.sg-sizing-tool-buttons--' + section);

    buttons.forEach(button => {
        if (button.getAttribute('data-key') !== data) {
            if (button.classList.contains('active')) {
                button.classList.remove('active');
            }
        } else {
            if (!button.classList.contains('active')) {
                button.classList.add('active');
            }
        }
    });
}

// Creates a custom element
export const customElement = function (element, clas, val = null, withHTML = false) {
    let el = document.createElement(element);

    el.setAttribute('class', clas);
    if (val)
        (withHTML) ? el.innerText = val : el.innerHTML = val;

    return el;
}

// Setting All Attrib
export const settingAllAttributes = (el, attribs) => {
    for (let a = 0; a < attribs.length; a++) {
        el.setAttribute(attribs[a][0], attribs[a][1]);
    }
}