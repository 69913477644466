// Version 2022.12

const isRequired = value => value === '' ? false : true;

const isEmailValid = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

const isPostCodeValid = postcode => (postcode - Math.floor(postcode) == 0 && postcode.toString().length == 4) ? true : false;
const isPhoneValid = phone => (phone - Math.floor(phone) == 0) ? true : false;

const fieldErrorMessage = (fieldname) => {
    switch (fieldname) {
        case 'sgform-name': return 'Please enter your name.';
        case 'sgform-email': return 'Please enter your email, so we can contact you.';
        case 'invalidEmail': return 'Your email is invalid, please try again.';
        case 'sgform-postcode': return 'Please enter your valid postcode.';
        case 'sgform-subject': return 'Please add a subject.';
        case 'sgform-message': return 'Please tell us what are your needs or how we may help you.';
    }
}

const enableSubmitButton = (form) => {
    if (form == 'safeguard-enquiry-form') {
        let customerName = document.querySelector('[name="sgform-name"]'),
            customerEmail = document.querySelector('[name="sgform-email"]'),
            customerPostCode = document.querySelector('[name="sgform-postcode"]'),
            customerSubject = document.querySelector('[name="sgform-subject"]'),
            customerMessage = document.querySelector('[name="sgform-message"]'),
            submitButton = document.querySelector('.safeguard-buttons--submit.contact-us');

        if (isRequired(customerName.value) && isRequired(customerEmail.value) && isEmailValid(customerEmail.value) && isRequired(customerPostCode.value) && isPostCodeValid(customerPostCode.value) && isRequired(customerSubject.value) && isRequired(customerMessage.value)) {
            submitButton.disabled = false;
        } else {
            submitButton.disabled = true;
        }
    } else if (form == 'safeguard-custom-net-request') {
        let customerName = document.querySelector('[name="sgform-name"]'),
            customerEmail = document.querySelector('[name="sgform-email"]'),
            customerMessage = document.querySelector('[name="sgform-message"]'),
            submitButton = document.querySelector('.safeguard-buttons--submit.custom-net-request');

        if (isRequired(customerName.value) && isRequired(customerEmail.value) && isEmailValid(customerEmail.value) && isRequired(customerMessage.value)) {
            submitButton.disabled = false;
        } else {
            submitButton.disabled = true;
        }
    }
}

export const formStateHandle = (e) => {
    let errorNote = document.querySelector('.' + e.target.parentElement.classList[0] + '.' + e.target.parentElement.classList[1] + ' .safeguard-form__error-message');

    if (!isRequired(e.target.value)) {
        e.target.style.border = '1px solid #D23D38';

        if (errorNote.classList.contains('hide')) {
            errorNote.classList.remove('hide');
        }

        errorNote.innerHTML = fieldErrorMessage(e.target.name);
    } else {
        if (e.target.name == 'sgform-email') {
            if (!isEmailValid(e.target.value)) {
                e.target.style.border = '1px solid #D23D38';

                if (errorNote.classList.contains('hide')) {
                    errorNote.classList.remove('hide');
                }

                errorNote.innerHTML = fieldErrorMessage(e.target.name);
            } else {
                e.target.style.border = '1px solid #868686';

                if (!errorNote.classList.contains('hide')) {
                    errorNote.classList.add('hide');
                }

                errorNote.innerHTML = '&zwj;';
            }
        } else if (e.target.name == 'sgform-postcode') {
            if (!isPostCodeValid(e.target.value)) {
                e.target.style.border = '1px solid #D23D38';

                if (errorNote.classList.contains('hide')) {
                    errorNote.classList.remove('hide');
                }

                errorNote.innerHTML = fieldErrorMessage(e.target.name);
            } else {
                e.target.style.border = '1px solid #868686';

                if (!errorNote.classList.contains('hide')) {
                    errorNote.classList.add('hide');
                }

                errorNote.innerHTML = '&zwj';
            }
        } else if (e.target.name == 'sgform-phone') {
            if (!isPhoneValid(e.target.value)) {
                e.target.style.border = '1px solid #D23D38';

                if (errorNote.classList.contains('hide')) {
                    errorNote.classList.remove('hide');
                }

                errorNote.innerHTML = fieldErrorMessage(e.target.name);
            } else {
                e.target.style.border = '1px solid #868686';

                if (!errorNote.classList.contains('hide')) {
                    errorNote.classList.add('hide');
                }

                errorNote.innerHTML = '&zwj';
            }
        } else {
            e.target.style.border = '1px solid #868686';

            if (!errorNote.classList.contains('hide')) {
                errorNote.classList.add('hide');
            }

            errorNote.innerHTML = '&zwj;';
        }
    }

    enableSubmitButton(e.target.form.name);
}

export const maxCharLimit = (e) => {
    const textAreaRemainingCharsField = document.querySelector('.safeguard-form-textarea-char-left');

    var chars = e.target.value.length,
        totChars = 550,
        remaining = totChars - chars;

    if (chars > 450) {
        textAreaRemainingCharsField.classList.remove('grin');
        textAreaRemainingCharsField.classList.add('rid');
    } else {
        textAreaRemainingCharsField.classList.remove('rid');
        textAreaRemainingCharsField.classList.add('grin');
    }

    if (remaining > 0) {
        textAreaRemainingCharsField.innerHTML = remaining + ' remaining';
    } else {
        textAreaRemainingCharsField.innerHTML = 'Too much text!';
    }
}