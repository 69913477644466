// Version 2023.03

import {
    connectingData,
    whatServer,
    customElement,
    settingAllAttributes,
    getSubCatgories,
    sizingToolButtonSwitch,
    wpMediaPhoto,
    getPages,
    onlineStores,
    whatTheme
} from "./global";

export const vehiclesData = async (id = '/?per_page=100') => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/safeguard-vehicles' + id);
        return JSON.parse(results.responseText);
    }
    catch (err) {
        return err;
    }
}

const netsData = async (id = '/?per_page=100') => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/safeguard-nets' + id);
        return JSON.parse(results.responseText);
    }
    catch (err) {
        return err;
    }
}

// INIT
export const initSizingToolApp = () => {
    // Session Storage
    // # Vehicle Type ID
    // # Vehicle Type Slug
    // # Vehicle Size ID
    // # Vehicle Size With Toolbox or not
    // # Vehicle Cargo Type

    let vehicleTypeID = sessionStorage.getItem('vehicle-type-id');

    if (vehicleTypeID) {
        sizingToolButtonSwitch(vehicleTypeID, 'vehicle-types');
        showVehicleSizes();

        if (sessionStorage.getItem('vehicle-size-id')) {
            if (sessionStorage.getItem('vehicle-size-type') == 'true') {
                displayToolBoxRecommendations();
            } else {
                enableCargoTypeOptions();

                if (sessionStorage.getItem('cargo-load-type')) {
                    showNetRecommendations();
                }
            }
        }
    }
}

// RESTORE DEFAULTS
export const restoreDefaults = () => {
    // Recommendations
    const container = document.querySelector('.sg-sizing-tool-app__module.recommendations-here');

    if (!container.classList.contains('hide')) {
        container.classList.remove('hide');
        container.innerHTML = '&zwj;';
    }

    // Cargo Load Type Section
    document.querySelector('.sg-sizing-tool-app__module.cargo-load').classList.remove('off');
    restoreDefaultCargoTypeSection();
}

// Show Chosen Vehicle Sizes
export const showVehicleSizes = () => {
    const container = document.querySelector('.sg-sizing-tool-app__module.vehicle-sizes .sg-sizing-tool-app__module-options');
    container.innerHTML = '';

    // Remove Default Message 
    document.querySelector('.sg-sizing-tool-app__module.vehicle-sizes .sg-sizing-tool-app__module-default-message').style.display = 'none';

    // Activate This Section
    document.querySelector('.sg-sizing-tool-app__module.vehicle-sizes').classList.remove('disabled');

    if (sessionStorage.getItem('vehicle-type-slug') === 'safeguard-vehicle-boat' || sessionStorage.getItem('vehicle-type-slug') === 'safeguard-vehicle-roof-rack' || sessionStorage.getItem('vehicle-type-slug') === 'safeguard-vehicle-truck') {
        defaultLayoutSizesOption(container);
    } else {
        complexLayoutSizeOption(container);
    }
}

const defaultLayoutSizesOption = (container) => {
    if (!container.classList.contains('default-layout')) {
        container.classList.add('default-layout');
    }
    if (container.classList.contains('complex-layout')) {
        container.classList.remove('complex-layout');
    }

    vehiclesData('/?categories=' + sessionStorage.getItem('vehicle-type-id')).then((sizes) => {
        sizes.reverse();
        sizes.forEach(size => {
            const buttonLabel = customElement('h4', 'sg-sizing-tool-buttons__option-title', size.title.rendered),
                button = customElement('button', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--vehicle-sizes', '');

            if (size.id == sessionStorage.getItem('vehicle-size-id')) {
                button.classList.add('active');
            }

            sessionStorage.setItem('subcat-icon', sessionStorage.getItem('vehicle-type-slug'));

            settingAllAttributes(button, [
                ['data-key', size.id],
                ['data-type', size.acf['sg-vehicle-with-without-toolbox']],
                ['data-subcat', sessionStorage.getItem('vehicle-type-slug')]
            ]);

            button.append(buttonLabel);
            container.append(button);
        })
    });
}

const complexLayoutSizeOption = (container) => {
    if (!container.classList.contains('complex-layout')) {
        container.classList.add('complex-layout');
    }
    if (container.classList.contains('default-layout')) {
        container.classList.remove('default-layout');
    }

    getSubCatgories('/?parent=' + sessionStorage.getItem('vehicle-type-id') + '&orderby=name&&order=desc').then((subcategories) => {
        subcategories.forEach(subcat => {
            const group = customElement('div', 'sg-sizing-tool-app__module-option-group', ''),
                icon = customElement('img', 'sg-sizing-tool-app__module-option-group-icon', '');

            settingAllAttributes(icon, [
                ['src', whatServer() + '/wp-content/themes/' + whatTheme() + '/dist/assets/backend-images/vehicle-icons/' + subcat.slug + '-icon.svg'],
                ['alt', subcat.name],
                ['width', '120'],
                ['height', 'auto']
            ]);

            group.append(
                icon,
                customElement('h4', 'sg-sizing-tool-app__module-option-group-title', subcat.name)
            );

            vehiclesData('/?categories=' + subcat.id).then((sizes) => {
                sizes.reverse();
                sizes.forEach(size => {
                    const buttonLabel = customElement('p', 'sg-sizing-tool-buttons__option-title', size.title.rendered),
                        button = customElement('button', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--vehicle-sizes', '');

                    if (size.id == sessionStorage.getItem('vehicle-size-id')) {
                        button.classList.add('active');
                    }

                    settingAllAttributes(button, [
                        ['data-key', size.id],
                        ['data-type', size.acf['sg-vehicle-with-without-toolbox']],
                        ['data-subcat', subcat.slug]
                    ]);

                    button.append(buttonLabel);
                    group.append(button);
                });
            });

            container.append(group);
        });
    });
}

export const displayToolBoxRecommendations = () => {
    const container = document.querySelector('.sg-sizing-tool-app__module.recommendations-here'),
        insideContainer = customElement('article', 'sg-sizing-tool-app__inner-module', ''),
        options = customElement('div', 'sg-sizing-tool-app__module-options', ''),
        imgToolBox = customElement('img', 'sg-sizing-tool-app__module-recommendations-option-image', ''),
        imgCustom = customElement('img', 'sg-sizing-tool-app__module-recommendations-option-image', ''),
        toolbox = customElement('div', 'sg-sizing-tool-app__card-results default toolbox', ''),
        custom = customElement('div', 'sg-sizing-tool-app__card-results default custom', ''),
        toolboxLink = customElement('a', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--results toolboxes', 'learn more'),
        customLink = customElement('a', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--results toolboxes', 'learn more');

    container.innerHTML = '';

    if (container.classList.contains('hide')) {
        container.classList.remove('hide');
    }

    // Hiding Cargo Load Section for this option
    document.querySelector('.sg-sizing-tool-app__module.cargo-load').classList.add('off');

    settingAllAttributes(toolboxLink, [
        ['href', whatServer() + '/safeguard-toolbox-nets'],
        ['target', '_blank']
    ]);

    settingAllAttributes(customLink, [
        ['href', whatServer() + '/custom-cargo-nets'],
        ['target', '_blank']
    ]);

    settingAllAttributes(imgToolBox, [
        ['src', whatServer() + '/wp-content/themes/' + whatTheme() + '/dist/assets/backend-images/sizing-tool-app/safeguard-toolbox-nets-hero-image-sizing-tool-app.jpg'],
        ['alt', 'Safeguard Toolbox Nets'],
        ['width', '100%'],
        ['height', 'auto']
    ]);

    settingAllAttributes(imgCustom, [
        ['src', whatServer() + '/wp-content/themes/' + whatTheme() + '/dist/assets/backend-images/sizing-tool-app/safeguard-custom-cargo-nets-hero-image-sizing-tool-app.jpg'],
        ['alt', 'Safeguard Custom Cargo Nets'],
        ['width', '100%'],
        ['height', 'auto']
    ]);

    toolbox.append(
        imgToolBox,
        customElement('h3', 'sg-sizing-tool-app__module-recommendations-title', 'toolbox nets'),
        customElement('p', 'sg-sizing-tool-app__module-recommendations-desc', 'The Safeguard Toolbox Nets are specifically designed for utes and trucks that carry toolboxes. These have a 1000 kg load rating.'),
        toolboxLink
    );

    custom.append(
        imgCustom,
        customElement('h3', 'sg-sizing-tool-app__module-recommendations-title', 'custom cargo nets'),
        customElement('p', 'sg-sizing-tool-app__module-recommendations-desc', 'We provide custom-made nets to cater to requirements that may not be met with our readily-available nets'),
        customLink
    );

    options.append(toolbox, custom);
    insideContainer.append(
        customElement('div', 'sg-sizing-tool-app__module-border', '<hr /><span><i class="fa-solid fa-arrow-down-long"></i></span>'),
        customElement('h2', 'sg-sizing-tool-app__module-title results', 'vehicles with toolboxes'),
        customElement('p', 'sg-sizing-tool-app__module-subtitle', 'Trays with toolboxes/objects require custom combinations of our netting products'),
        options
    );

    container.append(insideContainer);
}

export const enableCargoTypeOptions = () => {
    // init
    restoreDefaultCargoTypeSection();

    // Activate This Section
    document.querySelector('.sg-sizing-tool-app__module.cargo-load').classList.remove('disabled');

    // display data
    vehiclesData('/' + sessionStorage.getItem('vehicle-size-id') + '?_fields=acf.sg-vehicle-without-toolbox-section').then((data) => {
        let loads = data.acf['sg-vehicle-without-toolbox-section'],
            bulkyLoads = loads['sg-vehicle-recommendations-for-bulk-loads'],
            flatLoads = loads['sg-vehicle-recommendations-for-flat-loads'];

        // Is universal net option available?
        sessionStorage.setItem('universal-net', loads['sg-vehicle-universal-net-recommendations']);

        if (flatLoads['sg-vehicle-standard-nets-for-flat-cargo-load']) {
            cargoButtonEnable(
                'flat-load',
                flatLoads['sg-vehicle-standard-nets-for-flat-cargo-load'],
                flatLoads['sg-vehicle-weatherproof-nets-for-flat-cargo-load']
            );
        }

        if (bulkyLoads['sg-vehicle-standard-nets-for-bulky-cargo-load']) {
            cargoButtonEnable(
                'bulky-load',
                bulkyLoads['sg-vehicle-standard-nets-for-bulky-cargo-load'],
                bulkyLoads['sg-vehicle-weatherproof-nets-for-bulky-cargo-load']
            );
        }
    });
}

const restoreDefaultCargoTypeSection = (status = 'off') => {
    const buttons = document.querySelectorAll('.sg-sizing-tool-buttons--cargo-load');
    buttons.forEach(button => {
        if (button.disabled != true) {
            button.disabled = true;
            button.firstElementChild.innerHTML = '&zwj;';
            button.removeAttribute('data-reg');
            button.removeAttribute('data-dry');
            button.classList.remove('active');
        }
    });

    if (status == 'off') {
        document.querySelector('.sg-sizing-tool-app__module.cargo-load').classList.add('disabled');
    }
}

const cargoButtonEnable = (type, reg, dry) => {
    const button = document.querySelector('.sg-sizing-tool-buttons--cargo-load.' + type),
        icon = customElement('img', 'sg-sizing-tool-buttons__icon', ''),
        iconContainer = document.querySelector('.sg-sizing-tool-buttons--cargo-load.' + type + ' .sg-sizing-tool-buttons__icon-container');

    button.disabled = false;

    button.setAttribute('data-key', type);
    button.setAttribute('data-reg', reg);
    button.setAttribute('data-dry', dry);

    if (type == sessionStorage.getItem('cargo-load-type')) {
        button.classList.add('active');
    }

    settingAllAttributes(icon, [
        ['src', whatServer() + '/wp-content/themes/' + whatTheme() + '/dist/assets/backend-images/vehicle-icons/' + sessionStorage.getItem('subcat-icon') + '-' + type + '-icon.svg'],
        ['alt', sessionStorage.getItem('subcat-icon')],
        ['width', '120'],
        ['height', 'auto']
    ]);

    iconContainer.append(icon);
}

export const showNetRecommendations = (netType = 'reg') => {
    const container = document.querySelector('.sg-sizing-tool-app__module.recommendations-here'),
        insideContainer = customElement('article', 'sg-sizing-tool-app__inner-module', ''),
        options = customElement('div', 'sg-sizing-tool-app__module-options', ''),
        optionKind = ['best-option', 'multipurpose'],
        optionLabels = ['perfect for you!', 'multipurpose net'],
        switchNetTypeButtons = customElement('div', 'sg-sizing-tool-app__net-type-options', ''),
        standardNetTypeButton = customElement('button', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--net-type-buttons', 'standard net'),
        weatherproofNetTypeButton = customElement('button', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--net-type-buttons', 'weatherproof net');

    let slugs = '';

    container.innerHTML = '';

    if (container.classList.contains('hide')) {
        container.classList.remove('hide');
    }

    insideContainer.append(
        customElement('div', 'sg-sizing-tool-app__module-border', '<hr /><span><i class="fa-solid fa-arrow-down-long"></i></span>'),
        customElement('h2', 'sg-sizing-tool-app__module-title results', 'we recommend:')
    );

    if (sessionStorage.getItem('universal-net') == 'true') {
        if (sessionStorage.getItem(netType + '-slug') !== '') {
            slugs = sessionStorage.getItem(netType + '-slug') + ',' + ((netType == 'reg') ? 'universal-mesh-nets' : 'universal-weatherproof-nets');
        } else {
            slugs = (netType == 'reg') ? 'universal-mesh-nets' : 'universal-weatherproof-nets';
            options.classList.add('single');
        }
    } else {
        slugs = sessionStorage.getItem(netType + '-slug');
        options.classList.add('single');
    }

    if (sessionStorage.getItem('dry-slug') !== '' || sessionStorage.getItem('universal-net') == 'true') {

        if (netType == 'reg') {
            standardNetTypeButton.setAttribute('disabled', '');
            weatherproofNetTypeButton.removeAttribute('disabled');
        } else {
            weatherproofNetTypeButton.setAttribute('disabled', '');
            standardNetTypeButton.removeAttribute('disabled');
        }

        standardNetTypeButton.setAttribute('data-code', 'reg');
        weatherproofNetTypeButton.setAttribute('data-code', 'dry');

        switchNetTypeButtons.append(
            standardNetTypeButton,
            weatherproofNetTypeButton
        );

        insideContainer.append(switchNetTypeButtons);
    }

    netsData('/?slug=' + slugs).then((nets) => {
        nets.forEach((net, index) => {

            const optionContainer = customElement('div', 'sg-sizing-tool-app__card-results', ''),
                upperSection = customElement('section', 'sg-sizing-tool-app__card-results-upper', ''),
                img = customElement('img', 'sg-sizing-tool-app__module-recommendations-option-image', ''),
                prodInfo = customElement('div', 'sg-sizing-tool-app__product-info', ''),
                priceInfoContainer = customElement('div', 'sg-sizing-tool-app__module-recommendations-option-price-info', ''),
                price = customElement('p', 'sg-sizing-tool-app__module-recommendations-option-price', ''),
                button = customElement('button', 'sg-sizing-tool-buttons sg-sizing-tool-buttons--results net-option', 'buy from our stockists now'),
                specs = customElement('div', 'sg-sizing-tool-app__module-recommendations-option-specs', ''),
                specsList = customElement('ul', 'sg-sizing-tool-app__module-recommendations-option-specs-list', '');

            wpMediaPhoto('/' + net.featured_media, 'full').then((imageLink) => {
                settingAllAttributes(img, [
                    ['src', imageLink],
                    ['alt', net.title.rendered],
                    ['width', '100%'],
                    ['height', 'auto']
                ]);
            });

            price.append(
                customElement('sup', '', '$'),
                net.acf['safeguard-net-price'],
                customElement('span', '', 'ea')
            );

            button.setAttribute('data-store', net.acf['safeguard-net-stock-code']);

            priceInfoContainer.append(
                price,
                customElement('p', 'sg-sizing-tool-app__module-recommendations-option-gst-freight', 'RRP (inc. GST, excl. freight)'),
                customElement('p', 'sg-sizing-tool-app__module-recommendations-option-price-update', 'updated price list: august 2022'),
                button
            );

            prodInfo.append(
                customElement('h3', 'sg-sizing-tool-app__module-recommendations-title product-name', net.title.rendered),
                customElement('p', 'sg-sizing-tool-app__module-recommendations-option-product-dimensions', net.acf['safeguard-net-length'] + ' x ' + net.acf['safeguard-net-width'] + ' m'),
                priceInfoContainer
            );

            if (sessionStorage.getItem('universal-net') == 'true') {
                upperSection.append(customElement('h4', 'sg-sizing-tool-app__module-recommendations-option-label', optionLabels[index]));
            }
            optionContainer.classList.add(optionKind[index]);

            upperSection.append(img, prodInfo);

            specsList.append(
                customElement('li', '', '<span>stock code</span>' + net.acf['safeguard-net-stock-code']),
                customElement('li', '', '<span>safe working load</span>' + net.acf['safeguard-safe-working-load'] + ' kg'),
                customElement('li', '', '<span>net type</span>' + net.acf['safeguard-net-types']),
                customElement('li', '', '<span>size</span>' + net.acf['safeguard-net-size']),
                customElement('li', '', '<span>dimensions</span>' + net.acf['safeguard-net-length'] + ' x ' + net.acf['safeguard-net-width'] + ' m'),
                customElement('li', '', '<span>eyelets</span>' + net.acf['safeguard-net-eyelets']),
                customElement('li', '', '<span>tabs</span>' + net.acf['safeguard-net-tabs']),
                customElement('li', '', '<span>warranty</span>3-Year Guarantee<br><span class="online-registration">with online registration</span>')
            );

            specs.append(
                customElement('h4', 'sg-sizing-tool-app__module-recommendations-option-label specs', 'specifications'),
                specsList
            );

            optionContainer.append(upperSection, specs);
            options.append(optionContainer);
        });

        insideContainer.append(options);
    });

    container.append(insideContainer);
}

// ONLINE STORES

export const displaySizingToolAppOnlineStores = (stockCode) => {
    const imageContainer = document.querySelector('.sg-sizing-tool-app-stores__product-image-container'),
        productName = document.querySelector('.sg-sizing-tool-app-stores__product-name span'),
        productImage = customElement('img', 'sg-sizing-tool-app-stores__product-image', ''),
        listContainer = document.querySelector('.sg-sizing-tool-app-stores-list');

    imageContainer.innerHTML = '';
    productName.innerHTML = '';
    listContainer.innerHTML = '';

    netsData('/?_fields=featured_media,acf,title&per_page=100').then((nets) => {
        nets.forEach((net) => {

            if (net.acf['safeguard-net-stock-code'] == stockCode.toUpperCase()) {
                wpMediaPhoto('/' + net.featured_media, 'full').then((imageLink) => {
                    settingAllAttributes(productImage, [
                        ['src', imageLink],
                        ['alt', net.title.rendered],
                        ['width', '100%'],
                        ['height', 'auto']
                    ]);
                });

                productName.innerHTML = net.title.rendered;
                imageContainer.append(productImage);
            }
        });
    });

    onlineStores().then((stores) => {
        stores.forEach(store => {

            if (store.acf['safeguard-' + stockCode + '-link'] !== '' && store.acf['safeguard-' + stockCode + '-link'] !== null) {
                const item = customElement('article', 'sg-sizing-tool-app-stores-list__item', ''),
                    imgBox = customElement('section', 'sg-sizing-tool-app-stores-list__item-image-box', ''),
                    img = customElement('img', 'sg-sizing-tool-app-stores-list__item-image', ''),
                    button = customElement('button', 'sg-sizing-tool-app-stores-list__item-button', 'shop now'),
                    link = customElement('a', 'sg-sizing-tool-app-stores-list__item-link', '');

                settingAllAttributes(link, [
                    ['href', store.acf['safeguard-' + stockCode + '-link']],
                    ['target', '_blank']
                ]);

                wpMediaPhoto('/' + store.featured_media, 'full').then((imageLink) => {
                    settingAllAttributes(img, [
                        ['src', imageLink],
                        ['alt', store.title.rendered],
                        ['width', '100%'],
                        ['height', 'auto']
                    ]);
                });

                imgBox.append(img);
                item.append(link, imgBox, button);
                listContainer.append(item);
            }

        });
    });
}

export const restoreDefaultSizingToolAppOnlineStores = () => {
    document.querySelector('.sg-sizing-tool-app-stores__product-image-container').innerHTML = '&zwj;';
    document.querySelector('.sg-sizing-tool-app-stores__product-name').innerHTML = '&zwj;';
    document.querySelector('.sg-sizing-tool-app-stores-list').innerHTML = '&zwj;';
}